import React from "react";
import Modal from "./Modal";

function VideosDiplomadoSTEM({ setOpenModal }) {
  return (
    <Modal modalOpen={setOpenModal} title={"Sobre Diplomado STEM y Kurios Lab"}>
      <div
        style={{
          width: "100%",
          maxHeight: "80vh",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
       
        <div
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        >
          <a
            href="https://www.youtube.com/watch?v=YfF1t6YXCcY"
            target="_blank"
            rel="noreferrer"
            style={{
              background: "#330072",
              color: "#ffffff",
              // cursor: "not-allowed",
              borderRadius: "10px",
              padding: "10px",
              width: "fit-content",
            }}
          >
            Innovación
          </a>
        </div>
        <div
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        >
          <a
            href="https://www.youtube.com/watch?v=WBB8k7IZ4qg"
            target="_blank"
            rel="noreferrer"
            style={{
              background: "#330072",
              color: "#ffffff",
              // cursor: "not-allowed",
              borderRadius: "10px",
              padding: "10px",
              width: "fit-content",
            }}
          >
            Formando Generación
          </a>
        </div>

      </div>
    </Modal>
  );
}

export default VideosDiplomadoSTEM;
