import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { INSTITUTIONS_ENDPOINT } from '../../utils/constants';
import { get } from '../../utils/http';
import { useLoading } from '../../context/LoadingContext';
import LoadingComponent from '../LoadingComponent';
import styles from './InstitutionCalendar.module.css'; // Importa las clases CSS

function EventContent({ event }) {
  const tooltipContent = (
    <>
      <p>{event.extendedProps.grade} - Sec: {event.extendedProps.section}</p>
      <p>{event.title}</p>
      <p>{event.extendedProps.teacher}</p>
      <p>{formatTime(event.start)} - {formatTime(event.end)}</p>
    </>
  );

  return (
    <Tooltip 
      title={tooltipContent} 
      placement="top" 
      arrow 
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#333',
            color: '#fff',
            fontSize: '14px',
            padding: '10px'
          }
        }
      }}
    >
      <div>
        <b style={{ fontSize: '11px' }}>{`${event.extendedProps.grade} ${event.extendedProps.section} - ${event.title}`}</b>
        <p>{event.extendedProps.teacher}</p>
      </div>
    </Tooltip>
  );
}

const formatTime = (date) => {
  const options = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };
  return date?.toLocaleTimeString([], options);
};

// Leyenda de colores para los stages
const Legend = () => (
  <div className={styles.legend}>
    <div className={styles.legendItem}>
      <div className={`${styles.legendColor} ${styles.eventPreescolar}`}></div>
      <span>Preescolar</span>
    </div>
    <div className={styles.legendItem}>
      <div className={`${styles.legendColor} ${styles.eventPrimaria}`}></div>
      <span>Primaria</span>
    </div>
    <div className={styles.legendItem}>
      <div className={`${styles.legendColor} ${styles.eventBachillerato}`}></div>
      <span>Bachillerato</span>
    </div>
  </div>
);

function InstitutionCalendar({ institutionId }) {
  const [institutionCalendar, setInstitutionCalendar] = useState(null);
  const [mappedEvents, setMappedEvents] = useState({});
  const { showLoading, hideLoading, isLoading } = useLoading();

  useEffect(() => {
    showLoading();
    const fetchInstitution = async () => {
      const url = `${INSTITUTIONS_ENDPOINT}${institutionId}/calendar/`;
      const response = await get({ url });
      if(!response.error){
        setInstitutionCalendar(response.data);
      }
      hideLoading();
    };
    fetchInstitution();
  }, [institutionId]);

  useEffect(() => {
    const events = [];
    institutionCalendar && institutionCalendar?.forEach(event => {
      const currentWeekStart = new Date();
      currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());

      const dayIndex = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"].indexOf(event.day);
      const eventDate = new Date(currentWeekStart);
      eventDate.setDate(currentWeekStart.getDate() + dayIndex + 1);

      const startTime = event.start_time.split(':');
      const endTime = event.end_time.split(':');
      const start = new Date(eventDate);
      const end = new Date(eventDate);
      start.setHours(parseInt(startTime[0]), parseInt(startTime[1]));
      end.setHours(parseInt(endTime[0]), parseInt(endTime[1]));

      // Asignar clase basada en el stage usando styles
      let eventClass = '';
      switch (event.stage) {
        case 'Bachillerato':
          eventClass = styles.eventBachillerato;
          break;
        case 'Primaria Mayor':
          eventClass = styles.eventPrimaria;
          break;
        case 'Primaria Menor':
          eventClass = styles.eventPrimaria;
          break;
        case 'Preescolar':
          eventClass = styles.eventPreescolar;
          break;
        default:
          eventClass = '';
      }

      events.push({
        title: event.program,
        start: start,
        end: end,
        classNames: [eventClass],
        extendedProps: {
          teacher: event.teacher,
          section: event.section,
          grade: event.grade,
          stage: event.stage,
        },
      });
    });
    setMappedEvents(events);
  }, [institutionCalendar]);

  const currentDate = new Date();
  const currentWeekStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
  const currentWeekEnd = new Date(currentWeekStart.getFullYear(), currentWeekStart.getMonth(), currentWeekStart.getDate() + 6);

  return (
    <div>
      <Legend /> {/* Renderizar la leyenda aquí */}
      <FullCalendar
        plugins={[timeGridPlugin]}
        initialView="timeGridWeek"
        height={"78vh"}
        events={mappedEvents}
        slotDuration="00:15:00"
        nowIndicator={true}
        slotMinTime={"07:00:00"}
        slotMaxTime={"18:30:00"}
        weekends={false}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridWeek,timeGridDay',
        }}
        allDaySlot={false}
        eventContent={({ event }) => (
          <EventContent event={event} />
        )}
        validRange={{ start: currentWeekStart, end: currentWeekEnd }}
      />
      {isLoading && <LoadingComponent />}
    </div>
  );
}

export default InstitutionCalendar;