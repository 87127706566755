import React, { useState, useContext, useEffect, useRef } from "react";
import Container from "../components/Container";
import { CurrentUserContext } from "../context/CurrentUserProvider";
import { UsersContext } from "../context/UsersProvider";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import styles from "./ProfilePage.module.css";
import DiplomadoSTEM from "../components/DiplomadoSTEM";
import VideosDiplomadoSTEM from "../components/ModalVideosDiplomado";
import { IconButton } from "@mui/material";
import { patch } from "../utils/http";
import { TEACHERS_ENDPOINT } from "../utils/constants";
import { AlertsContext } from "../context/AlertsContext";

const ProfilePage = () => {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const { fetchSingleUser, updateUser } = useContext(UsersContext);
  const showSnackbar = useContext(AlertsContext);
  const [selectedUser, setSelectedUser] = useState({});
  const [isEditing, setisEditing] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [openModalVideos, setOpenModalVideos] = useState(false)

  const { userId } = useParams();
  const navigate = useNavigate();


  const fileInputRef = useRef(null);

  const fetchProfile = async () => {
    const Id = userId ? userId : currentUser?.id;
    const response = await fetchSingleUser(Id);
    setSelectedUser(response);
  };

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("img_profile", file);
    updateUser({ userId, data: formData })
    fetchProfile()
  };

  const role = selectedUser.employee_profile
    ? "Empleado"
    : selectedUser.teacher_profile
    ? "Docente"
    : "Usuario";

  const handleEdit = () => {
    if (isEditing) {
      setisEditing(false);
      navigate("view");
    } else {
      setisEditing(true);
      navigate("edit");
    }
  };

  const handleAcept = async (formId) => {

    setCurrentUser({
      ...currentUser,  
      monitoring_form: currentUser?.monitoring_form?.map((form) => {
                                              return form.id === formId ? {...form, is_loading: true} : form; 
                                          })
    })

    let response = await patch({url: `${TEACHERS_ENDPOINT}mark_read_feedback/`, data: {monitoring_form_id: formId, is_read_feedback: true}})
    let newCurrentUser = {}

    if (!response.error) {
    
      let is_read_feedback = response?.data?.is_read_feedback

      showSnackbar("Feedback marcado como leído", "success");
      newCurrentUser =  {
        ...currentUser,  
        monitoring_form: currentUser?.monitoring_form?.map((form) => {
                                                return form.id === formId ? { 
                                                                              ...form, 
                                                                              is_read_feedback: is_read_feedback, 
                                                                              is_loading: false
                                                                            } 
                                                                          : form; 
                                            })
      }
      
    } else {
      showSnackbar("Hubo un error al marcar el feedback", "error");
      newCurrentUser =  {
        ...currentUser,  
        monitoring_form: currentUser?.monitoring_form?.map((form) => {
                                                return form.id === formId ? {...form, is_loading: false} : form; 
                                            })
      }

    };

    setCurrentUser(newCurrentUser)

  };

  useEffect(() => {
    fetchProfile();
    navigate(`${!userId ? `${currentUser?.id}/` : ""}view`, { replace: true });
  }, [userId]);

  return (
    <div className={styles.profile}>
      <div className={styles["main-info"]}>
        <div className={styles["profile-icon-edit"]}>
          {selectedUser?.img_profile ? 
            <img src={selectedUser?.img_profile} style={{width: '100px', borderRadius: '50%', height: '100px'}}/> :
            <i  style={{fontSize: '100px'}} className="fas fa-user-circle"></i>}
          {selectedUser?.id === currentUser?.id &&
            <><IconButton type="button" className={styles["edit-button"]} onClick={handleEditClick}>
              <i class="fas fa-pen" style={{color: 'var(--sec-blue)'}}></i>
            </IconButton>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }} 
          /></>}
        </div>
        <span
          className={styles["main-info__name"]}
        >{`${selectedUser["first_name"]} ${selectedUser["last_name"]}`}</span>
        <span className={styles["main-info__role"]}>{role}</span>
        
        <div className={styles.diplomado} onClick={() => setOpenModal(true)}>
          Diplomado STEM
        </div>

        <div className={styles.aboutdiplomado} onClick={() => setOpenModalVideos(true)}>
            Sobre Diplomado STEM
        </div>
      </div>

      <div className={styles["content"]}>
        <div className={styles.header}>
          <h1>Perfil</h1>
          <i className="fal fa-pen" onClick={handleEdit}></i>
          
        </div>
        <Container direction="column">
          <Outlet
            context={{
              selectedUser: [selectedUser, setSelectedUser],
              setSelectedUser: (user) => { setSelectedUser(user) },
              handleEdit,
            }}
          ></Outlet>
          {selectedUser?.id === currentUser?.id && (
            <div style={{ width: "100%" }}>
              <p style={{ fontSize: "17px", marginLeft: "2rem" }}>
                Visitas de acompañamiento
              </p>
              {currentUser?.monitoring_form ? (
                <>
                {currentUser?.monitoring_form && currentUser?.monitoring_form?.map((form) => (
                  <div className={styles.monitor}>
                  <p style={{ fontSize: "13px" }}>
                    {form?.course_section?.program} -{" "}
                    {form?.course_section?.level.name} -
                    Sección:{" "}
                    {form?.course_section?.name}
                  </p>
                  <p style={{ fontSize: "13px", marginTop: "10px" }}>
                    Fecha de la visita: {form?.date}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    Feedback
                  </p>
                  <p style={{ fontSize: "13px", marginTop: "5px" }}>
                    {form?.feedback}
                  </p>
                  {form.is_read_feedback === false ? (
                    <div>
                      <IconButton
                        type="button"
                        className={styles["edit-button"]}
                        sx={{
                            backgroundColor: 'var(--sec-green)', 
                            '&:hover': {
                              backgroundColor: 'var(--sec-green)',
                            },
                            color: '#fff',
                            font: 'var(--font)',
                            marginTop: '10px',
                            borderRadius: '10px',
  
                        }}
                        onClick={() => handleAcept(form.id)}
                        disabled={form?.is_loading}
                      >
                        <i class="fas fa-check" style={{color: '#fff', backgroundColor: 'var(--sec-green)', marginRight: '5px'}}></i>
                        { form?.is_loading ? "Cargando..." : "Marcar como leído" }
                      </IconButton>
                    </div>
                  ) : ( 
                    <div>
                      <IconButton
                        type="button"
                        className={styles["edit-button"]}
                        disabled={true}
                        sx={{
                            backgroundColor: 'var(--sec-green)', 
                            '&:hover': {
                              backgroundColor: 'var(--sec-green)',
                            },
                            color: '#fff',
                            font: 'var(--font)',
                            marginTop: '10px',
                            borderRadius: '10px',

                        }}
                      >
                        <i class="fas fa-check" style={{color: '#fff', backgroundColor: 'var(--sec-green)', marginRight: '5px'}}></i>
                        Feedback Leído
                      </IconButton>
                    </div>
                  )
                  }
                 
                  
                </div>

                )) }
                </>
              ) : (
                <div className={styles.monitor}>
                  <p style={{ fontSize: "12px" }}>No hay ninguna planilla</p>
                </div>
              )}
            </div>
          )}
        </Container>
      </div>
      {openModal && <DiplomadoSTEM setOpenModal={setOpenModal}/>}
      {openModalVideos && <VideosDiplomadoSTEM setOpenModal={setOpenModalVideos}/>}
    </div>
  );
};

export default ProfilePage;
